import React from 'react';
import PropTypes from 'prop-types';
// Style
import './prev-button.scss';

const PrevButton = (props) => {
  const { style, onClick } = props;
  return (
    <div
      role="button"
      tabIndex={0}
      className="prevSlideButton flex-middle flex-center"
      style={{ ...style }}
      onKeyDown={onClick}
      onClick={onClick}
    >
      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 9L5 5L1 1"
          stroke="#24273F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

PrevButton.propTypes = {
  style: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PrevButton;
