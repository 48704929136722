/* eslint-disable react/no-danger */
import React from 'react';
import html from 'remark-html';
import markdown from 'remark-parse';
import unified from 'unified';
import PropTypes from 'prop-types';

// Components
import Link from '../../components/Link/Link';
import SEO from '../../components/SEO/SEO';
import Carousel from '../../components/Carousel/Carousel';
import CustomBreadcrumb from '../../components/CustomBreadcrumb/CustomBreadcrumb';
import MicroLayout from '../../layouts/Micro';
// import PricingTable from "../../components/AppMarketplace/PricingTable/PricingTable";
// import ToggleSwitch from "../../components/AppMarketplace/ToggleSwitch/ToggleSwitch";

// Style
import './app-details.scss';

// Utils
import titleCase from '../../utils/titleCase';

const AppPage = ({ pageContext, location }) => {
  const { appData } = pageContext;
  // const [checked, setChecked] = useState(false);
  return (
    <MicroLayout>
      <div className="app-detail-page">
        <SEO title={appData.app_title} description={appData.app_desc} />
        <div className="container">
          <div className="row">
            {/* Left Side START */}
            <div className="col-lg-2 col-md-3 col-sm-6 col-xs-6 p-3">
              <div className="app-logo flex flex-center flex-middle">
                <img
                  className="img-responsive"
                  width={200}
                  height={200}
                  src={appData.app_logo}
                  alt={appData.app_title}
                />
              </div>
              <div className="app-desc">
                <p className="text-muted text-left mt-2 mb-4">{appData.app_desc}</p>
              </div>

              <Link
                href={`https://dashboard.remoteteam.com/app-installs?app_identifier=${appData.app_identifier}`}
                className="text-capitalize text-white"
              >
                <div className="app-button py-1-2 px-1 text-center">
                  <span>Install Now</span>
                </div>
              </Link>
            </div>
            {/* Left Side END */}
            {/* Right Side START */}
            <div className="col-lg-4 col-md-3 col-sm-6 col-xs-6">
              <div className="row m-0">
                <div className="col-lg-6 px-0 py-2">
                  <CustomBreadcrumb location={location} />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 p-0">
                  {/* Discount Area */}
                  {/* {appData.app_discount.length > 0 && (
                  <div className="row m-0">
                    <span className="app-discount-badge text-center ml-6">
                      {appData.app_discount.toString()}%
                    </span>
                  </div>
                )} */}
                  {/* Discount Area */}
                  <div className="row m-0 flex-middle">
                    <div className="col-lg-2 col-md-5 col-sm-6 col-xs-6">
                      <h1 className="text-dark">{appData.app_title}</h1>
                    </div>

                    <div className="col-lg-4 col-md-1 col-sm-6 col-xs-6">
                      <Link href={`/app-marketplace/${appData.app_tag.toString()}`}>
                        <span className="app-tag-badge flex flex-center flex-middle px-3 py-1">
                          <p>{titleCase(appData.app_tag.toString().replace(/-+/g, ' '))}</p>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* Slider */}
              <div className="mt-1 mb-3">
                <Carousel>
                  {appData.app_slides.map((node) => (
                    <img key={node.url} src={node.url} width="100%" alt={appData.app_desc} />
                  ))}
                </Carousel>
              </div>
              {/* Slider */}
              {/* Overview Content */}
              <div className="row">
                <div
                  className="app-overview-content"
                  dangerouslySetInnerHTML={{
                    __html: unified()
                      .use(markdown)
                      .use(html)
                      .processSync(appData.app_overview_content),
                  }}
                />
              </div>
              {/* Overview Content */}
            </div>
            {/* Right Side End */}

            {/* {(appData.app_features.length > 0 &&
            appData.app_monthly_plans.length > 0) ||
          appData.app_annually_plans.length > 0 ? (
            <div className="row mt-4" style={{ width: "100%" }}>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div className="row flex-middle flex-between">
                  <div className="col-lg-3 col-md-3 col-sm-4 col-xs-4">
                    <h1>Pricing</h1>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-2 col-xs-2 flex-end">
                    {appData.app_features.length &&
                    appData.app_monthly_plans.length &&
                    appData.app_annually_plans.length ? (
                      <ToggleSwitch
                        id={"toggleSwitch"}
                        checked={checked}
                        optionLabels={["Monthly", "Annually"]}
                        onChange={(checkedData) => setChecked(checkedData)}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <PricingTable
                    appTitle={appData.app_title}
                    appLogo={appData.app_logo}
                    appIntegrations={appData.app_integration_list}
                    planFeatures={appData.app_features}
                    monthlyPlans={appData.app_monthly_plans}
                    annuallyPlans={appData.app_annually_plans}
                    discountPercent={appData.app_discount[0]}
                    selectedPlan={
                      !checked && appData.app_monthly_plans.length > 0
                        ? "monthly"
                        : "annually"
                    }
                  />
                </div>
              </div>
            </div>
          ) : null}
           */}

            <div className="row my-3" style={{ width: '100%' }}>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <p className="feedback-text text-muted text-center">
                  Is there a problem with the page?{' '}
                  <a href="mailto:content@remoteam.com" title="Contact Us">
                    Contact Us
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MicroLayout>
  );
};

AppPage.propTypes = {
  pageContext: PropTypes.objectOf.isRequired,
  location: PropTypes.objectOf.isRequired,
};

export default AppPage;
