import React from 'react';
import PropTypes from 'prop-types';
import Link from '../Link/Link';

// Custom Style
import './custom-breadcrumb.scss';

const CustomBreadcrumb = ({ location, centered }) => {
  const mainUrl = `${location.protocol}//${location.host}`;
  const crumbLabels = location.pathname.split('/').filter((item) => item);
  if (location && location.protocol) {
    return (
      <div className={`breadcrumb ${centered ? 'breadcrumb-center' : ''}`}>
        <ul className="breadcrumb__list text-center">
          <li className="breadcrumb__item">
            <Link className="breadcrumb__link" href={mainUrl}>
              RemoteTeam /
            </Link>
            {crumbLabels &&
              crumbLabels.length &&
              crumbLabels.map((label, index) => (
                <Link
                  className="breadcrumb__link"
                  key={Math.random().toString(36).substr(2, 9)}
                  href={`${mainUrl}/${crumbLabels
                    .slice(0, index + 1)
                    .map((labelData) => labelData)
                    .join('/')}/`}
                >
                  {label.replace('-', ' ')} {crumbLabels.length - 1 !== index ? '/' : ''}
                </Link>
              ))}
          </li>
        </ul>
      </div>
    );
  }
  return <></>;
};

CustomBreadcrumb.defaultProps = {
  centered: false,
};

CustomBreadcrumb.propTypes = {
  location: PropTypes.objectOf.isRequired,
  centered: PropTypes.bool,
};
export default CustomBreadcrumb;
