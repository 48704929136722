/* eslint-disable react/prop-types */
import React from 'react';
import Slider from 'react-slick';

import PrevButton from './PrevButton/PrexButton';
import NextButton from './NextButton/NextButton';

// Styles
import './slick.scss';
import './slick-theme.scss';
import './carousel-style.scss';

const Carousel = ({
  children,
  dots,
  infinite,
  arrows,
  autoplay,
  speed,
  slideToShow,
  slideToScroll,
  nextArrow,
  prevArrow,
}) => {
  // Slider Settings
  const settings = {
    dots: dots ?? false,
    infinite: infinite ?? true,
    arrows: arrows ?? true,
    lazyLoad: true,
    speed: speed ?? 2000,
    autoplay: autoplay ?? true,
    slidesToShow: slideToShow ?? 1,
    slidesToScroll: slideToScroll ?? 1,
    nextArrow: nextArrow ?? <PrevButton />,
    prevArrow: prevArrow ?? <NextButton />,
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slider {...settings}>{children}</Slider>;
};

export default Carousel;
