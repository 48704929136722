import React from 'react';
import PropTypes from 'prop-types';

// Style
import './next-button.scss';

const NextButton = (props) => {
  const { style, onClick } = props;
  return (
    <div
      role="button"
      tabIndex={0}
      className="nextSlideButton flex-middle flex-center"
      style={{ ...style }}
      onKeyUp={onClick}
      onClick={onClick}
    >
      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5 1L1 5L5 9"
          stroke="#24273F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

NextButton.propTypes = {
  style: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NextButton;
